<template>
  <el-container class="order-preview" style="display: block;">
      <el-main class="main">
          <el-row>
              <el-col :span="24"><div class="goods-title">商品信息</div></el-col>
          </el-row>
          <div v-if="orderInfo.attrInfo">
              <el-row class="main-row">
                <el-col :span="3" :align="middle">订单编号：</el-col>
                <div class="value">{{orderInfo.order_sn}}</div>
              </el-row>
              <el-row class="main-row">
                  <el-col :span="3" :align="middle">商品图片：</el-col>
                  <el-col :span="21">
                      <el-image :src="orderInfo.attrInfo.absolute_pic" :lazy="true" :preview-src-list="[orderInfo.attrInfo.absolute_pic]">
                          <slot name="error">
                              <div class="image-slot">
                                  <i class="el-icon-picture-outline"></i>
                              </div>
                          </slot>
                          <slot name="placeholder">
                              <div class="image-slot">
                                  <i class="el-icon-picture-outline"></i>
                              </div>
                          </slot>
                      </el-image>
                  </el-col>
              </el-row>
              <el-row class="main-row">
                  <el-col :span="3">商品名称：</el-col>
                  <el-col :span="21"><div class="goods-name" @click="$router.push({name: 'productDetailPage', params: {id: orderInfo.attrInfo.id}})">{{orderInfo.attrInfo.goods_name}}</div></el-col>
              </el-row>
              <el-row class="main-row">
                  <el-col :span="3">商品价格：</el-col>
                  <el-col :span="21"><strong>￥{{orderInfo.attrInfo.price}}</strong></el-col>
              </el-row>
              <el-row class="main-row">
                  <el-col :span="3">商品数量：</el-col>
                  <el-col :span="21">{{orderInfo.cart_num}}</el-col>
              </el-row>
              <el-row class="main-row">
                  <el-col :span="3">小计：</el-col>
                  <el-col :span="21">{{orderInfo.attrInfo.price * orderInfo.cart_num}}</el-col>
              </el-row>
          </div>
          <div v-else>
              <el-empty description="没有商品信息"></el-empty>
          </div>
      </el-main>
      <el-main class="footer">
          <el-row>
              <el-col :span="24" class="footer-col real-pay">
                  <span class="label">实付款：</span><span class="total-price">￥{{orderInfo.total_fee}}</span>
              </el-col>
          </el-row>
          <el-row>
              <el-col :span="24" class="footer-col">
                  <span class="label">寄送至：</span>{{orderInfo.address}}
              </el-col>
          </el-row>
          <el-row>
              <el-col :span="24" class="footer-col">
                  <span class="label">收货人：</span>{{orderInfo.name}} {{orderInfo.mobile}}
              </el-col>
          </el-row>
          <el-row>
              <el-col :span="24" class="footer-col order-submit">
                  <el-button type="success" @click="$router.back()">返回上一页</el-button>
              </el-col>
          </el-row>
      </el-main>
  </el-container>
  
</template>

<script>
import { ref } from 'vue'
import { ElButton, ElCol, ElContainer, ElEmpty, ElFooter, ElImage, ElMain, ElRow } from 'element-plus';
import 'element-plus/dist/index.css'
import { useRoute } from 'vue-router'
import { detail } from '@/api/order'

export default {
  name: 'orderPreview',
  setup() {
      const route = useRoute();

      // 获取订单详情
      const orderInfo = ref({});
      detail({ id: route.query.id }).then(res => {
        try {
          res.data.attrInfo = JSON.parse(res.data.attr)
        } catch (e) {
          console.log('orderInfo attr is not json, attr: ', res.data.attr);
          res.data.attrInfo = undefined;
        }
        orderInfo.value = res.data;
      });

      return {
        orderInfo
      }
  },
  components: {
      [ElContainer.name]: ElContainer,
      [ElMain.name]: ElMain,
      [ElFooter.name]: ElFooter,
      [ElImage.name]: ElImage,
      [ElRow.name]: ElRow,
      [ElCol.name]: ElCol,
      [ElButton.name]: ElButton,
      [ElEmpty.name]: ElEmpty
  }
}
</script>

<style lang="less" scoped>
  .order-preview {
      .goods-title {
          font-size: 16px;
          font-weight: 700;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #cccccc;
      }
      
      .total-price {
          color: #ff0000;
          font-size: 20px;
      }

      .main-row {
          margin-top: 5px;
      }

      .label {
          font-weight: 700;
      }

      .footer-col {
          text-align: right;
      }

      .real-pay {
          margin-bottom: 10px
      }

      .order-submit {
          margin-top: 10px
      }

      .main, .footer {
          background: white;
      }

      .footer {
          margin-top: 10px;
          padding: 10px 10px 15px 10px;
      }
      
      .goods-name {
        cursor: pointer;
      }
  }
</style>